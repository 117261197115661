import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../features/auth/authActions";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";

export default function Header() {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();


  return (
    <div className="navbar bg-base-100 shadow-lg border-b-4 mb-4">
      <div className="navbar-start">
        {userInfo ? (
          <>
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost lg:hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[20] mt-3 w-52 p-2 shadow"
              >
                <li>
                  <Link to="/attachements" target="_self">
                    Mes attachements
                  </Link>
                </li>
                <li>
                  {(userInfo.role === "admin" ||
                    userInfo.role === "gestionnaire") && (
                    <a href="/admin">Panel d'admininistration</a>
                  )}
                </li>
              </ul>
            </div>
          </>
        ) : (
          <></>
        )}
        <a href="/attachement" className="-m-1.5 p-1.5">
          <span className="sr-only">Spie</span>
          <img
            className="h-16 w-auto"
            src="/img/logo-attachement.png"
            alt=""
          />
        </a>
      </div>
      {userInfo ? (
        <>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <Link to="/attachements" target="_self">
                  Mes attachements
                </Link>
              </li>
              <li>
                {(userInfo.role === "admin" ||
                  userInfo.role === "gestionnaire") && (
                  <a href="/admin">Panel d'admininistration</a>
                )}
              </li>
            </ul>
          </div>
          <div className="navbar-end">
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost btn-circle avatar"
              >
                <div className="w-10 rounded-full">
                  <Avatar username={userInfo.prenom} />
                </div>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[20] mt-3 w-52 p-2 shadow"
              >
                <li>
                  <a href="/profile" className="justify-between">
                    Mon compte
                  </a>
                </li>
                <li>
                  <Link
                    target="_self"
                    onClick={async (event) => {
                      dispatch(userLogout());
                    }}
                  >
                    Se déconnecter
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <div className="navbar-end space-x-4">
          <a href="/login">Se connecter</a>
        </div>
      )}
    </div>
  );
}
