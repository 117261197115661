import { useEffect, useState } from "react";
import { Table } from "../../Components/attachement/table";
import { makeRequest } from "../../Utils";
import { toast } from "react-toastify";
import AttachmentOrderModal from "./AttachmentOrderModal";
import { RotateSpinner } from "react-spinners-kit";
const AttachementConfig = () => {
  const [isLoading, setLoading] = useState(false)
  const [tabsData, setTabsData] = useState([]);
  const [addData, setAddData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSectionOpen, setModalSectionOpen] = useState(false);
  const [modalOrderOpen, setModalOrderOpen] = useState(false);
  const [addSectionLibelle, setSectionLibelle] = useState("");
  const [form, setForm] = useState({
    noArticle: "",
    content: "",
    pts: 0,
    quantite: 0,
  });

  const [tabsKeysOrder, setTabsKeysOrder] = useState([]);

  let toastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const handleQuantityChange = (item, index, key, newQuantity) => {
    setTabsData((prevData) => {
      const tabIndex = prevData.findIndex((tab) => tab.label === key);
      if (tabIndex !== -1) {
        const newData = [...prevData];
        Object.values(newData[tabIndex].value).forEach((array) => {
          const itemIndex = array.findIndex(
            (i) => i.noArticle === item.noArticle
          );
          if (itemIndex !== -1) {
            array[itemIndex].quantite = newQuantity;
          }
        });
        return newData;
      }
      return prevData;
    });
  };
  const addRow = (key, tabIndex) => {
    setAddData({ key, tabIndex });
    setForm({
      noArticle: "",
      content: "",
      pts: 0,
      quantite: 0,
    });
    setModalOpen(true);
  };

  const handleSaveAddRow = () => {
    const { key, tabIndex } = addData;
    const newData = [...tabsData];

    newData[tabIndex].value[key].push(form); // Ajoutez un nouvel élément à la liste de données
    setTabsData(newData);
    setModalOpen(false);
  };

  const updateRow = (item, key, tabIndex, indexValue, data) => {
    const newData = [...tabsData];
    newData[tabIndex].value[key][indexValue] = data;
  };

  const deleteRow = (key, tabIndex, indexValue) => {
    setTabsData((prevTabsData) => {
      const newData = [...prevTabsData];

      if (indexValue !== -1 && indexValue !== null) {
        newData[tabIndex].value[key].splice(indexValue, 1);
      } else {
        delete newData[tabIndex].value[key];
      }
      return newData;
    });
  };

  const saveChanges = async () => {
    setLoading(true)
    const promises = tabsData.map((element) => {
      const formData = new FormData();
      formData.append(`attachements`, JSON.stringify(element));

      return makeRequest(
        "api/attachement/config/updateAttachement",
        "PUT",
        null,
        formData
      );
    });

    Promise.all(promises)
      .then((data) => {
        toast.success(
          "Vos modifications ont bien été prises en compte.",
          toastOptions
        );
      })
      .catch((error) => {
        toast.error(
          "Une erreur s'est produite ! merci de ressayer dans quelques instants ou de contacter un Administrateur",
          toastOptions
        );
        console.error("An error occurred", error);
      }).finally(() => {setLoading(false)});
  };
  const addSection = async () => {
    if (!addSectionLibelle) {
      toast.error("Veuillez renseigner le libellé de la section", toastOptions);
      return;
    }

    const newTab = {
      label: addSectionLibelle.toUpperCase(), // Remplacez par le label de votre choix
      value: {
        [addSectionLibelle.toUpperCase()]: [],
      }, // Remplacez par les valeurs de votre choix
    };

    setTabsData((prevTabsData) => [...prevTabsData, newTab]);
    setSectionLibelle("");
    setModalSectionOpen(false);
  };

  const handleOrderSave = (items) => {
    setLoading(true);
    const promises = items.map((element) => {
      const formData = new FormData();
      formData.append(`id`, element.id);
      formData.append(`pos`, element.pos);

      return makeRequest(
        "api/attachement/config/updatePosition",
        "PUT",
        null,
        formData
      );
    });

    Promise.all(promises)
      .then(() => {
        toast.success(
          "Vos modifications ont bien été prises en compte.",
          toastOptions
        );
        fetchAttachements();
      })
      .catch((error) => {
        toast.error(
          "Une erreur s'est produite ! merci de ressayer dans quelques instants ou de contacter un Administrateur",
          toastOptions
        );
        console.error("An error occurred", error);
      });
  };

  const handleModalOpen = (isOpen) => {
    setModalOrderOpen(isOpen);
  };

  const fetchAttachements = async () => {
    setLoading(true);
    makeRequest("api/attachement/config/getAttachements").then((data) => {
      setTabsData(data?.attachements);
      setTabsKeysOrder(
        data?.attachements?.map((tab) => ({
          id: tab.id,
          pos: tab.pos,
          content: tab.label,
        }))
      );
    }).finally(() => {setLoading(false)});
  };

  useEffect(() => {
    fetchAttachements();
    // makeRequest("api/attachement/config/getAttachements").then((data) => {
    //   setTabsData(data?.attachements);
    //   console.log("Attachements", data?.attachements);
    //   setTabsKeysOrder(
    //     data?.attachements?.map((tab) => ({
    //       id: tab.id,
    //       pos: tab.pos,
    //       content: tab.label,
    //     }))
    //   );
    // });
  }, []);

  return (
    <div className="w-full h-full">
      {tabsData.length !== 0 && (
        <div className="w-full p-8">
          <div className="flex flex-wrap items-center justify-left my-8 space-x-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline  mb-4"
              onClick={() => saveChanges()}
            >
              Enregistrer les modifications
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
              onClick={() => setModalSectionOpen(true)}
            >
              Ajouter une section
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
              onClick={() => setModalOrderOpen(true)}
            >
              Modifier l&apos;ordre des sections
            </button>
          </div>
          <table className="table-auto table w-full text-center border-collapse">
            <thead>
              <tr>
                <th className="border w-10"></th>
                <th className="border w-1/4">No Article</th>
                <th className="border w-2/5">Désignation</th>
                <th className="border w-1/5">Pts</th>
                <th className="border w-1/5">Quantité</th>
              </tr>
            </thead>
            <Table
              all={true}
              selectedTab={undefined}
              handleQuantityChange={handleQuantityChange}
              tabsData={tabsData}
              editMode={true}
              addRow={addRow}
              updateRow={updateRow}
              deleteRow={deleteRow}
            />
          </table>

          {modalOpen && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-8 max-w-md mx-auto">
                <h2 className="text-2xl font-bold mb-6 text-center"></h2>
                <div className="flex flex-wrap -mx-2 mb-4 ">
                  <label className="w-full md:w-1/2 px-2 mb-4 md:mb-0 ">
                    No Article
                    <input
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      value={form.noArticle}
                      onChange={(e) =>
                        setForm({ ...form, noArticle: e.target.value })
                      }
                    />
                  </label>
                  <label className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                    Désignation
                    <input
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      value={form.content}
                      onChange={(e) =>
                        setForm({ ...form, content: e.target.value })
                      }
                    />
                  </label>
                  <label className="w-full md:w-1/2 px-2">
                    Points
                    <input
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      value={form.pts}
                      type="number"
                      onChange={(e) =>
                        setForm({ ...form, pts: e.target.value })
                      }
                    />
                  </label>
                  <label className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                    Quantité
                    <input
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      value={form.quantite}
                      type="number"
                      onChange={(e) =>
                        setForm({ ...form, quantite: e.target.value })
                      }
                    />
                  </label>
                </div>
                <div className="flex justify-end space-x-4 mt-6">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSaveAddRow}
                  >
                    Sauvegarder
                  </button>
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => setModalOpen(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          )}

          {modalSectionOpen && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-8 max-w-md mx-auto">
                <h2 className="text-2xl font-bold mb-6 text-center"></h2>
                <div className="flex flex-wrap -mx-2 mb-4 ">
                  <label className="w-full ">
                    Libellé de la nouvelle section
                    <input
                      className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      value={addSectionLibelle}
                      onChange={(e) => setSectionLibelle(e.target.value)}
                    />
                  </label>
                </div>
                <div className="flex justify-end space-x-4 mt-6">
                  <button
                    className={`bg-blue-500 text-white font-bold py-2 px-4 rounded ${
                      addSectionLibelle.length < 1
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-blue-700"
                    }`}
                    onClick={addSection}
                    disabled={addSectionLibelle.length < 1}
                  >
                    Sauvegarder
                  </button>
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => setModalSectionOpen(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          )}

          {modalOrderOpen && (
            <AttachmentOrderModal
              orderList={tabsKeysOrder}
              handleOrderSave={handleOrderSave}
              handleModalOpen={handleModalOpen}
            />
          )}
        </div>
      )}
      {isLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-white bg-opacity-80 flex justify-center items-center z-50">
          <RotateSpinner size={45} color="#318ce7" loading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default AttachementConfig;
