import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../Utils";
import { toast } from "react-toastify";

let toastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
export const registerUser = createAsyncThunk(
  "auth/register",
  async (
    { prenom, nom, email, password, role, site = null, otherUser = false },
    { rejectWithValue }
  ) => {
    try {
      const data = await makeRequest(
        "api/user/register",
        "POST",
        null,
        JSON.stringify({ prenom, nom, email, password, role, site, otherUser })
      );

      if (!data) {
        // return custom error message from API if any
        if (data?.msg) {
          return rejectWithValue(data.msg);
        } else {
          return rejectWithValue("Error registering user");
        }
      }
      toast.success(
        `${
          otherUser
            ? "Le compte de l'utilisateur a bien été crée"
            : "Votre compte a bien été crée"
        }`,
        toastOptions
      );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ password, token }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("password", password);
      formData.append("token", token);

      const data = await makeRequest(
        "api/user/updatePassword",
        "PUT",
        null,
        formData
      );

      if (!data) {
        // return custom error message from API if any
        if (data?.msg) {
          return rejectWithValue(data.msg);
        } else {
          return rejectWithValue("Erreur lors de la création du mot de passe");
        }
      }
      toast.success("Mot de passe mis à jour", toastOptions);
      return { ...data, success: true };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const data = await makeRequest(
        "api/user/login",
        "POST",
        null,
        JSON.stringify({ email, password })
      );

      if (!data) {
        // return custom error message from API if any
        if (data?.msg) {
          return rejectWithValue(data.msg);
        } else {
          return rejectWithValue("Login failed");
        }
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const data = await makeRequest("api/user/logout", "POST");

      if (!data) {
        // return custom error message from API if any
        if (data?.msg) {
          return rejectWithValue(data.msg);
        } else {
          return rejectWithValue("Logout failed");
        }
      }
      toast.success("Vous êtes maintenant déconnecté", toastOptions);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      const data = await makeRequest(
        `api/user/delete?id=${userId}`,
        "DELETE",
        null,
        null
      );
      if (!data) {
        // return custom error message from API if any
        if (data?.msg) {
          return rejectWithValue(data.msg);
        } else {
          return rejectWithValue("Error during delete user");
        }
      }
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);
