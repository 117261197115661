import { useEffect, useState } from "react";
import { makeRequest } from "../Utils";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "../Components/CircularProgress";
import { toast } from "react-toastify";
const toastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
const AttachementList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(
    queryParams.get("status") || "inProgress"
  );
  const onSort = (columnName) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === columnName &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }

    // Set the sort configuration
    setSortConfig({ key: columnName, direction: direction });
    console.log(data);
    // Sort the data
    let sortedData = [...data].sort((a, b) => {
      if (a[columnName] === null) return direction === "ascending" ? 1 : -1; // place les valeurs null à la fin pour le tri ascendant et au début pour le tri descendant
      if (b[columnName] === null) return direction === "ascending" ? -1 : 1; // place les valeurs null à la fin pour le tri ascendant et au début pour le tri descendant
      if (a[columnName] < b[columnName]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    // Set the sorted data
    setData(sortedData);
  };

  const fetchData = async () => {
    try {
      let url = "api/attachement/getAllAttachementsByUserId";
      let params = new URLSearchParams();

      if (status) {
        params.append("status", status);
      }
          if (search) {
            params.append("search", search);
          }

      makeRequest(`${url}?${params.toString()}`)
        .then((data) => setData(data))
        .finally(() => {
          setLoading(false);
        });
      //setData(data);
      // console.log(data);
    } catch (error) {
      toast.error("Erreur lors de la récupération des données", toastOptions);
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [search,status]);

  return (
    <div className="w-full p-8">
      <h1 className="text-2xl font-bold text-center mb-8">
        Liste des attachements
      </h1>
      <div className="relative flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 lg:space-x-10 mb-4 border py-6 bg-slate-100">
        <div>
          <label className="absolute top-0 left-0 -mt-5 bg-blue-400 rounded-full px-2 py-1">
            Filtre
          </label>
        </div>
        <div>
          <label className="input input-bordered flex items-center gap-2 ">
            <input
              type="text"
              className="grow placeholder:text-sm"
              placeholder="Recherche par référence"
              onChange={(e) => setSearch(e.target.value)}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-blue-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 10h16M4 14h16M4 18h16"
            />
          </svg>
          <div className="relative">
            <select
              name="status"
              id="status"
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none pr-8"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="" disabled>
                Sélectionner un statut
              </option>
              <option value="pending">En attente de traitement</option>
              <option value="inProgress">En cours de création</option>
              <option value="validate">Validé</option>
            </select>
            {status && (
              <div
                className="absolute inset-y-0 right-0 flex items-center  cursor-pointer"
                onClick={() => setStatus("")}
              >
                <svg
                  className="h-8 w-8 "
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                    fill="#0F0F0F"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>

      {data?.length !== 0 ? (
        <table className="table w-full text-center border-collapse">
          <thead>
            <tr>
              {/* <th className="border"></th> */}
              <th className="border" onClick={() => onSort("reference")}>
                <span style={{ userSelect: "none" }}>Référence</span>
                {sortConfig &&
                  sortConfig.key === "reference" &&
                  (sortConfig.direction === "ascending" ? "▲" : "▼")}
              </th>
              <th className="border" onClick={() => onSort("nom")}>
                <span style={{ userSelect: "none" }}>Nom</span>
                {sortConfig &&
                  sortConfig.key === "nom" &&
                  (sortConfig.direction === "ascending" ? "▲" : "▼")}
              </th>
              <th className="border" onClick={() => onSort("prenom")}>
                <span style={{ userSelect: "none" }}>Prénom</span>
                {sortConfig &&
                  sortConfig.key === "prenom" &&
                  (sortConfig.direction === "ascending" ? "▲" : "▼")}
              </th>
              <th className="border" onClick={() => onSort("status")}>
                <span style={{ userSelect: "none" }}>Statut</span>
                {sortConfig &&
                  sortConfig.key === "status" &&
                  (sortConfig.direction === "ascending" ? "▲" : "▼")}
              </th>
              <th className="border" onClick={() => onSort("created_at")}>
                <span style={{ userSelect: "none" }}>Crée le</span>
                {sortConfig &&
                  sortConfig.key === "created_at" &&
                  (sortConfig.direction === "ascending" ? "▲" : "▼")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr
                className="border cursor-pointer hover:bg-blue-200"
                key={index}
                onClick={() => {
                  if (item.status === "inProgress") {
                    navigate(`/attachement/${item.id}`,{state : {referrer : location.pathname}});
                  }
                  else {
                    toast.error(
                      `Vous ne pouvez pas modifier cet attachement puisqu'il est au statut "${item.status == "Pending"
                    ? "En attente de traitement"
                    : item.status == "inProgress"
                    ? "En cours de création"
                    : "Validé"}"`,
                      toastOptions
                    );
                  }
                }}
              >
                {/* <td className="flex  items-center text-left justify-left space-x-2 px-4 py-2">
                  <CheckIcon className="h-6 w-6 text-green-200 hover:text-green-600 cursor-pointer" />
                  <TrashIcon
                    className="h-6  w-6 text-red-200 hover:text-red-600 cursor-pointer"
                    onClick={() => {}}
                  />
                </td> */}
                <td>{item.reference}</td>
                <td>{item.nom}</td>
                <td>{item.prenom}</td>
                <td>
                  {item.status == "Pending"
                    ? "En attente de traitement"
                    : item.status == "inProgress"
                    ? "En cours de création"
                    : "Validé"}
                </td>
                <td>
                  {new Date(item.created_at).toLocaleString("fr-FR", {
                    dateStyle: "full",
                    timeStyle: "short",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : loading ? (
        <div className="flex justify-center top-6">
          <CircularProgress />
        </div>
      ) : (
        <p className="text-center">Aucun résultat</p>
      )}
    </div>
  );
};

export default AttachementList;
