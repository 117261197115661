import React, { useState, useEffect, useRef } from "react";
import SearchBar from "../Components/maps/SearchBar";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import PartnerList from "../Components/maps/PartnerList";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "../Style/Marker/popup.css";
import Partner from "../Components/maps/Partner";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";
import { RotateSpinner } from "react-spinners-kit";
import AppChooser from "../screens/QRCodeLink";
import QRCode from "qrcode.react";

function Main({ qrocdeParam = false }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const qrcodeParam = urlParams.get("qrcode") || qrocdeParam.toString();
  const qrcodeLongitude = urlParams.get("longitude");
  const qrcodeLatitude = urlParams.get("latitude");
  const mapRef = React.useRef(null);
  const mapCenter = useRef([]);
  const [map, setMap] = useState(null);
  const [markerList, setMarkerList] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [QRCodeCoords, setQRCodeCoords] = useState({
    Latitude: 0,
    Longitude: 0,
  });

  const apiKeyHere = process.env.REACT_APP_API_KEY_HERE_MAPS;

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleMapClick = async (e) => {
    // Récupérez les coordonnées du point où l'utilisateur a cliqué
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;

    if (map && mapCenter.current.length > 0) {
      await removeMarker("Chantier");
      addMarker(
        "Chantier",
        [lat, lng],
        `<div class="marker-popup">
        <img src="img/chantier.png" style={{ maxWidth: '100%', height: 'auto' }}  alt="Emplacement Chantier">
          <p>Emplacement Chantier</p>
      </div>`
      );
      mapCenter.current = [lat, lng];
      setQRCodeCoords({
        Latitude: mapCenter.current[0],
        Longitude: mapCenter.current[1],
      });
    }
  };

  // Ajoutez un gestionnaire d'événement pour le clic sur la carte
  if (map && qrcodeParam === "true") {
    map.on("click", handleMapClick);
  }

  function removeMarkers() {
    if (map) {
      map.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });
    }
  }

  const FetchOther = async (at, options) => {
    removeMarkers();
    const baseUrl = "https://discover.search.hereapi.com/v1/discover";
    const lang = "fr-FR";
    const limit = 30;

    try {
      const url = `${baseUrl}?apiKey=${apiKeyHere}&q=${options}&lang=${lang}&limit=${limit}&at=${at}`;
      const response = await fetch(url);
      if (response.status === 429) {
        throw new Error("Too Many Requests");
      }
      const data = await response.json();

      const dataFournisseurOrder = data.items
        .filter((a) => {
          const prefix = a.address?.postalCode?.substring(0, 2);
          return (
            prefix?.startsWith("14") ||
            prefix?.startsWith("50") ||
            prefix?.startsWith("61")
          );
        })
        .sort((a, b) => {
          return a.distance - b.distance;
        });

      const sortList = await Promise.all(
        dataFournisseurOrder
          .filter((result) => result !== null && result !== undefined)
          .sort((a, b) => {
            return a.distance - b.distance;
          })
      );

      setMarkerList(sortList);
    } catch (e) {
      if (e.message === "Too Many Requests") {
        alert(
          "La limite de requête a été atteinte, veuillez réessayer plus tard"
        );
        setIsLoading(false);
      }
    }
  };

  const FetchPartners = async (at) => {
    removeMarkers();
    const baseUrl = "https://discover.search.hereapi.com/v1/discover";
    const lang = "fr-FR";
    const limit = 100;
    try {
      const results = await Promise.all(
        PartnerList.map(async (partner) => {
          try {
            const query = `${partner.name}`;
            const url = `${baseUrl}?apiKey=${apiKeyHere}&q=${query}&lang=${lang}&limit=${limit}&at=${at}`;
            const response = await fetch(url);
            if (response.status === 429) {
              throw new Error("Too Many Requests");
            }
            const data = await response.json();
            const dataFournisseurOrder = await data.items
              .filter((a) => {
                const prefix = a.address?.postalCode?.substring(0, 2);
                const normalizedTitle = a.title
                  ?.toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
                const normalizedPartnerName = partner.name
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
                const isSame = normalizedTitle.startsWith(
                  normalizedPartnerName
                );

                return (
                  (prefix?.startsWith("14") ||
                    prefix?.startsWith("50") ||
                    prefix?.startsWith("61")) &&
                  isSame
                );
              })
              .sort((a, b) => {
                return a.distance - b.distance;
              });

            if (dataFournisseurOrder.length > 0) {
              return dataFournisseurOrder[0];
            }
          } catch (e) {
            console.error(
              `Erreur lors de la récupération des données pour ${partner.name}:`,
              e
            );
            throw e;
          }
        })
      );

      const sortList = await Promise.all(
        results
          .filter((result) => result !== null && result !== undefined)
          .sort((a, b) => {
            return a.distance - b.distance;
          })
      );

      setMarkerList(sortList);
    } catch (e) {
      if (e.message === "Too Many Requests") {
        alert(
          "La limite de requête a été atteinte, veuillez réessayer plus tard"
        );
        setIsLoading(false);
      }
    }
  };

  const removeMarker = async (label) => {
    if (!map) return;

    await map.eachLayer(function (layer) {
      if (layer instanceof L.Marker && layer.options.name === label) {
        map.removeLayer(layer);
      }
    });
  };

  const addMarker = (label, position, info) => {
    if (!map) return;

    const blueIcon = L.icon({
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      iconSize: [25, 41],
      iconAnchor: [12, 41],
    });

    var myIcon = new L.Icon({
      iconUrl:
        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
      shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    if (label === "Chantier") myIcon = blueIcon;
    const marker = L.marker(position);

    marker.setIcon(myIcon);
    marker.options.name = label;

    marker.bindPopup(info);
    marker.addTo(map);
  };

  const mapDesign = async () => {
    if (map && mapCenter?.current?.length > 0) {
      await removeMarker("Chantier");
      addMarker(
        "Chantier",
        mapCenter.current,
        `<div class="marker-popup">
        <img src="img/chantier.png" style={{ maxWidth: '100%', height: 'auto' }}  alt="Emplacement Chantier">
          <p>Emplacement Chantier</p>
      </div>`
      );
      if (markerList?.length > 0) {
        await Promise.all(
          markerList.map(async (marker) => {
            if (marker?.title) {
              addMarker(
                marker.title,
                [marker.position.lat, marker.position.lng],
                `<div class="marker-popup">
                <p>${marker.title}</p>
              </div>`
              );
            }
          })
        );
      }

      map.setView(mapCenter.current, 10);

      setTimeout(() => {
        map.flyTo(map.getCenter(), 13, {
          duration: 1,
        });
      }, 500);
    } else if (!map && mapCenter?.current?.length > 0) {
      const mapInstance = new L.map("map", { zoomControl: false }).setView(
        mapCenter.current,
        10
      );

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 18,
      }).addTo(mapInstance);
      removeMarker("Chantier");

      const myIcon = L.icon({
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      });

      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(mapInstance);

      const marker = L.marker(mapCenter.current);

      marker.setIcon(myIcon);
      marker.options.name = "Chantier";

      marker.bindPopup(`<div class="marker-popup">
        <img src="img/chantier.png" alt="Emplacement Chantier">
          <p>Emplacement Chantier</p>
      </div>`);
      marker.addTo(mapInstance);
      setMap(mapInstance);
    }

    if (map && qrcodeParam === "true") {
      map.on("click", handleMapClick);
      setQRCodeCoords({
        Latitude: mapCenter.current[0],
        Longitude: mapCenter.current[1],
      });
    }
  };

  useEffect(() => {
    setIsLoading(false);
    setIsOpen(true);
    if (mapRef?.current) {
      mapDesign();
    }
  }, [markerList]);

  const handleSearch = async (coordinates, options) => {
    setIsLoading(true);

    if (coordinates?.length > 0) {
      const x = coordinates[0];
      const y = coordinates[1];
      if (qrcodeParam !== "true") {
        if (options === "Location" || options === null)
          await FetchPartners(`${y},${x}`);
        else FetchOther(`${y},${x}`, options);
      } else {
        if (mapRef?.current) {
          mapDesign(y, x);
        }
        setIsOpen(true);
        setIsLoading(false);
      }

      mapCenter.current = [y, x];
    }
  };

  const generateQRCode = (latitude, longitude) => {
    // const data = `waze://?ll=${latitude},${longitude}&navigate=yes`;
    const data = `https://spiemaps.go.yj.fr/qrcode?latitude=${latitude}&longitude=${longitude}`;
    return data;
  };

  const downloadQR = () => {
    const canvas = document.getElementById("qrcode");

    const resizedCanvas = document.createElement("canvas");
    const resizedContext = resizedCanvas.getContext("2d");

    // Définir les nouvelles dimensions
    const newWidth = 113.3859; // Remplacez par la largeur souhaitée
    const newHeight = 113.3859; // Remplacez par la hauteur souhaitée

    // Appliquer les nouvelles dimensions au nouveau canvas
    resizedCanvas.width = newWidth;
    resizedCanvas.height = newHeight;

    // Dessiner l'image originale sur le nouveau canvas sans rogner
    resizedContext.drawImage(canvas, 0, 0, newWidth, newHeight);

    // Convertir le canvas redimensionné en URL de données
    const resizedPngUrl = resizedCanvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    // Créer le lien de téléchargement
    let downloadLink = document.createElement("a");
    downloadLink.href = resizedPngUrl;
    downloadLink.download = "qrcode_spie_resized.png";

    // Ajouter le lien au corps du document
    document.body.appendChild(downloadLink);

    // Déclencher le téléchargement
    downloadLink.click();

    // Supprimer le lien du corps du document
    document.body.removeChild(downloadLink);
  };

  const hangleGenerateQRCode = () => {
    downloadQR();
  };

  const handleMarkerClick = async (label) => {
    const marker = markerList.find((m) => m.title === label);

    if (!map || !marker) return;

    await map.eachLayer(function (layer) {
      if (layer instanceof L.Marker && layer.options.name === label) {
        layer.openPopup();
        // centrer la carte sur le marqueur
        map.setView(layer.getLatLng(), map.zoom);
      }
    });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        mapCenter.current = [
          position.coords.latitude,
          position.coords.longitude,
        ];
        if (mapRef?.current) {
          mapDesign();
        }
      },
      (error) => {
        console.error("Erreur de géolocalisation", error);
        // Vous pouvez gérer l'erreur ici, par exemple en affichant un message à l'utilisateur
        switch (error.code) {
          case error.PERMISSION_DENIED:
            alert("L'utilisateur a refusé la demande de géolocalisation.");
            break;
          case error.POSITION_UNAVAILABLE:
            alert("Les informations de localisation ne sont pas disponibles.");
            break;
          case error.TIMEOUT:
            alert("La demande de localisation a expiré.");
            break;
          case error.UNKNOWN_ERROR:
            alert("Une erreur inconnue s'est produite.");
            break;
          default:
            alert("Une erreur inconnue s'est produite.");
            break;
        }
      },
      {
        enableHighAccuracy: true, // Optionnel : pour une localisation plus précise
        timeout: 5000, // Optionnel : temps maximum pour obtenir la localisation
        maximumAge: 0, // Optionnel : ne pas utiliser une localisation mise en cache
      }
    );
  }, []);

  useEffect(() => {
    if (!map) return;

    //retirerChargement();
    setTimeout(() => {
      map.flyTo(map.getCenter(), 13, {
        duration: 1,
      });
    }, 500);
  }, [map]);

  return (
    <>
      {qrcodeLatitude && qrcodeLongitude ? (
        <AppChooser longitude={qrcodeLongitude} latitude={qrcodeLatitude} />
      ) : (
        <>
          {isLoading && (
            <div className="fixed top-0 left-0 h-screen w-screen bg-white bg-opacity-80 flex justify-center items-center z-50">
              <RotateSpinner size={45} color="#318ce7" loading={isLoading} />
            </div>
          )}
          <div className="flex w-screen h-screen overflow-hidden">
            <div className="flex  w-screen h-screen overflow-hidden absolute">
              <div className="flex flex-col w-screen h-screen overflow-hidden">
                <div
                  className={`flex  items-center justify-between bg-gray-100 px-3 w-96 py-2 h-13 z-[11] transition-all duration-500 ease-in-out ${
                    isOpen ? "" : "-translate-x-[92%] "
                  }`}
                >
                  <SearchBar
                    onSearch={handleSearch}
                    qrcode={qrcodeParam}
                    onClickGenerateQRCode={hangleGenerateQRCode}
                  />
                  <button
                    className="focus:outline-none"
                    onClick={toggleSidebar}
                    hidden={
                      markerList
                        ? markerList.length > 0
                          ? ""
                          : "none"
                        : "none"
                    }
                  >
                    {isOpen ? (
                      <HiChevronLeft className="h-6 w-6" />
                    ) : (
                      <HiChevronRight className="h-6 w-6" />
                    )}
                  </button>
                </div>

                <div
                  className={`flex flex-col left-0 bg-white-100 z-[10] w-96 max-h-screen bottom-0 overflow-hidden transition-all duration-500 ease-in-out ${
                    isOpen ? "" : "-translate-x-full "
                  }`}
                >
                  {markerList
                    ? markerList.length > 0 && (
                        <div
                          className={`inset-x-0 bg-white scrollbar-thin  scrollbar-thumb-gray-400 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full w-full h-screen bottom-0 transition-all duration-500 ease-in-out ${
                            isOpen ? "" : "-translate-x-full"
                          }`}
                        >
                          <Partner
                            markerlist={markerList}
                            onClick={handleMarkerClick}
                          />
                        </div>
                      )
                    : ""}
                </div>
              </div>
            </div>
            <div style={{ display: "none" }}>
              <QRCode
                id="qrcode"
                value={generateQRCode(
                  QRCodeCoords.Latitude,
                  QRCodeCoords.Longitude
                )}
                size={128}
                level={"H"}
                includeMargin={false}
                imageSettings={{
                  x: undefined,
                  y: undefined,
                  height: 24,
                  width: 24,
                  excavate: true,
                }}
              />
              <button onClick={downloadQR}>Download QR</button>
            </div>
            <div
              id="map"
              ref={mapRef}
              className="flex w-full max-h-screen z-0"
            ></div>
          </div>
        </>
      )}
    </>
  );
}

export default Main;
