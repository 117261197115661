import React,{ useEffect } from "react";
import {  Outlet, useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
import { CircularProgress } from "../Components/CircularProgress";

export default function AdminRoute() {
  
  const { loading, userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate(); 
  
  useEffect(() => {
    if (!userInfo && !loading) {
      navigate("/login");
    }
  }, [userInfo, loading,navigate]);

  if (loading) {
    return (
      <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
        <CircularProgress />
      </div>
    );
  }

  return <Outlet />;
}
