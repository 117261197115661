import Tabs from "../Components/attachement/tabs.js";
import { useState, useEffect } from "react";
import { makeRequest } from "../Utils/index.js";
import { RotateSpinner } from "react-spinners-kit";

function NewAttachement() {
  const [toggle, setToggle] = useState(
    localStorage.getItem("toggle")
      ? JSON.parse(localStorage.getItem("toggle"))
      : false
  );
  const [tabsData, setTabsData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    makeRequest("api/attachement/config/getAttachements").then((data) => {
      data?.attachements?.unshift({
        label: "Informations",
        value: {},
      });

      data?.attachements?.push({
        label: "Récap",
        value: {},
      });
      
      const tabsDatas = data?.attachements?.filter((tab) => {
        // Vérifie si tab.value est un tableau vide
        if (Array.isArray(tab.value) && tab.value.length === 0) {
          return false; // Exclut l'élément
        }
        // Vérifie que chaque tableau associé à chaque clé dans 'tab.value' a plus d'un élément
        return Object.values(tab.value).every((arr) => arr.length > 0);
      });

      setTabsData(tabsDatas);
    }).catch((error) => {console.error(error);}).finally(() => {setLoading(false)});
  }, []);

  const handleQuantityChange = (item, index, key, newQuantity) => {
    setTabsData((prevData) => {
      const tabIndex = prevData.findIndex((tab) => tab.label === key);
      if (tabIndex !== -1) {
        const newData = [...prevData];
        Object.values(newData[tabIndex].value).forEach((array) => {
          const itemIndex = array.findIndex(
            (i) => i.noArticle === item.noArticle
          );
          if (itemIndex !== -1) {
            array[itemIndex].quantite = newQuantity;
          }
        });
        return newData;
      }
      return prevData;
    });
  };

  return tabsData.length !== 0 ? (
    <div className="w-full h-full">
      <div className="flex items-center space-x-2">
        <p className="text-sm font-semibold">Combiner les onglets</p>
        <div
          onClick={() => {
            const newToggle = !toggle;
            setToggle(newToggle);
            localStorage.setItem("toggle", newToggle);
          }}
          className="bg-gray-100 cursor-pointer relative w-[60px] h-[30px] rounded-full"
        >
          <input
            type="checkbox"
            checked={toggle}
            id="check"
            className="sr-only peer w-full"
          />
          <span className="w-3/5 h-4/5 bg-rose-300 absolute rounded-full left-1 top-1 peer-checked:bg-rose-600 peer-checked:left-5 transition-all duration-500"></span>
        </div>
      </div>
      <Tabs
        tabsData={tabsData}
        onQuantityChange={handleQuantityChange}
        all={toggle}
      />

      {/* <div className="flex justify-center space-x-4 py-4">
        <button className="px-4 py-2 bg-blue-500 text-white rounded">
          Envoyer
        </button>
      </div> */}
    </div>
  ) : (
    <> 
      {isLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-white bg-opacity-80 flex justify-center items-center z-50">
          <RotateSpinner size={45} color="#318ce7" loading={isLoading} />
        </div>
      )}
    </>
  );
}

export default NewAttachement;
