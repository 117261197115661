import React, { useEffect, useState } from "react";
import { makeRequest } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "../../Components/CircularProgress";
const Dashboard = () => {
  const [stats, setStats] = useState({
    usersCount: 0,
    visitsCount: 0,
    pendingAttachments: 0,
    validateAttachments: 0,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Tableau de bord";
    setLoading(true);
    makeRequest("api/stats/dashboard", "GET")
      .then((data) => {
        setStats({
          usersCount: data?.nb_users,
          visitsCount: data?.nb_visitors,
          pendingAttachments: data?.pending_attachments,
          validateAttachments: data?.validated_attachments,
        });
      })
      .catch((error) => console.error("Error:", error))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="p-8 w-full">
        <h1 className="text-2xl font-bold mb-4">Tableau de bord</h1>
        <div className="flex justify-center top-6">
          <CircularProgress />;
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 w-full">
      <h1 className="text-2xl font-bold mb-4">Tableau de bord</h1>
      <div className="stats stats-vertical w-full lg:stats-horizontal shadow">
        <div
          className="stat cursor-pointer"
          onClick={() => navigate("/admin/userAttachements?status=Pending")}
        >
          <div className="stat-figure">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-8 h-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
              ></path>
            </svg>
          </div>
          <div className="stat-value">
            {Math.round(
              (stats.validateAttachments /
                (stats.pendingAttachments + stats.validateAttachments)) *
                100
            )}
            %
          </div>
          <div className="stat-title">Attachement traité</div>
          {stats.pendingAttachments > 0 && (
            <div className="stat-desc text-red-600">
              {stats.pendingAttachments} attachement(s) à traité
            </div>
          )}
        </div>

        <div className="stat">
          <div className="stat-title">Nombres de visites</div>
          <div className="stat-value text-primary">{stats.visitsCount}</div>
        </div>

        <div className="stat">
          <div className="stat-figure text-primary">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline-block w-8 h-8 stroke-current"
            >
              <circle cx="12" cy="6" r="4" stroke="#1C274C" strokeWidth="1.5" />
              <path
                d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634"
                stroke="#1C274C"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="stat-title">Nombre d&apos;inscrits</div>
          <div className="stat-value">{stats.usersCount}</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
