import React from "react";

const AppChooser = ({latitude,longitude}) => {
  const isIOS =/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center">
      <h1 className="text-sm font-semibold mb-4 flex-wrap">
        Veuillez choisir votre application avec laquelle vous voulez naviguer :
      </h1>
      <div className="flex space-x-4 flex-wrap  items-center">
        <a
          href={`https://www.waze.com/ul?ll=${latitude}%2C${longitude}&navigate=yes`}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center text-center"
        >
          <img src={require("../Assets/Images/waze_64 (1).png")} alt="Waze" />
          <p>waze</p>
        </a>
        <a
          href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center text-center "
        >
          <img
            src={require("../Assets/Images/google_maps_64.png")}
            alt="Google Maps"
          />
          Google maps
        </a>
        {isIOS && (
          <a
            href={`maps://?q=${latitude},${longitude}`}
            target="_blank"
            rel="noreferrer"
            className="flex flex-col items-center text-center"
          >
            <img
              src={require("../Assets/Images/maps_64.png")}
              alt="Apple Maps"
            />
            Apple Plan
          </a>
        )}
      </div>
    </div>
  );
};

export default AppChooser;
