import {
  PencilIcon,
  TrashIcon,
  PlusCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { hover } from "@testing-library/user-event/dist/hover";
import { useEffect, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const Table = ({
  tabsData,
  selectedTab,
  handleQuantityChange,
  all,
  editMode = false,
  addRow = undefined,
  updateRow = undefined,
  deleteRow = undefined,
}) => {
  const tabsKeys = all ? tabsData.map((_, index) => index) : [selectedTab];
  const [editingIndex, setEditingIndex] = useState(null);
  const [editData, setEditingdata] = useState({});
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedIndexValue, setSelectedIndexValue] = useState(null);

  const openModalDelete = (key, index, indexValue) => {
    setSelectedKey(key);
    setSelectedIndex(index);
    setSelectedIndexValue(indexValue);
    setIsModalDeleteOpen(true);
  };

  const closeModalDelete = () => {
    setIsModalDeleteOpen(false);
  };

  const confirmDelete = () => {
    closeModalDelete();
    deleteRow(selectedKey, selectedIndex, selectedIndexValue);
  };

  const handleInputValidation = (evt, item, index, label, edit = false) => {
    const value = evt.target.value;

    const itemQuantite = !edit ? item.quantite : editData.quantite;

    if (value === "" && itemQuantite?.toString().length < 2) {
      !edit
        ? handleQuantityChange(item, index, label, "")
        : setEditingdata({
            ...editData,
            quantite: "",
          });
      return; // Sortir de la fonction tôt
    }

    // Remplacer les virgules par des points pour uniformiser le format décimal
    let normalizedValue = value.replace(",", ".");

    // Utiliser une expression régulière pour vérifier si la valeur est un nombre positif sans signes préfixes
    const isPositiveNumberWithoutSign =
      /^([0-9]+([.,][0-9]*)?|[.,][0-9]+)$/.test(normalizedValue);

    if (
      (itemQuantite === 0 || itemQuantite?.toString() === "0") &&
      value !== "0" + "."
    ) {
      normalizedValue = value.replace(/^0+/, "");
    }

    if (isPositiveNumberWithoutSign) {
      // Si c'est un nombre positif sans signe, procéder avec la valeur normalisée
      !edit
        ? handleQuantityChange(item, index, label, normalizedValue)
        : setEditingdata({ ...editData, quantite: normalizedValue });
    } else {
      // Sinon, réinitialiser à la quantité précédente ou à 0
      !edit
        ? handleQuantityChange(item, index, label, itemQuantite || 0)
        : setEditingdata({ ...editData, quantite: itemQuantite || 0 });
    }
  };

  return (
    <tbody>
      {tabsKeys.map((index) => {
        const tab = tabsData[index];
        const entries = Object.entries(tab.value);
        return entries.length > 1 || all ? (
          entries
            ?.sort((a, b) => a[1][0]?.noArticle - b[1][0]?.noArticle)
            .map(([key, value], itemIndex) => (
              <>
                <tr key={key}>
                  {editMode && (
                    <th
                      colSpan="1"
                      className="flex items-center px-4 py-2 text-left  justify-end "
                    >
                      <TrashIcon
                        className="h-6 w-6  text-red-200 hover:text-red-600 cursor-pointer "
                        onClick={() => {
                          openModalDelete(key, index, null);
                        }}
                      />
                    </th>
                  )}
                  <th
                    colSpan={editMode ? "4" : "5"}
                    className="border bg-gray-200 font-bold text-center"
                  >
                    {key}
                  </th>
                </tr>
                {value?.map((item, indexValue) => (
                  <tr key={indexValue}>
                    {editMode && (
                      <td className="px-4 py-2 text-left flex items-center space-x-2">
                        {editingIndex?.indexValue === indexValue &&
                        editingIndex?.index === index &&
                        editingIndex?.itemIndex === itemIndex ? (
                          <>
                            <CheckIcon
                              className="h-6 w-6 text-green-600 hover:text-green-400 cursor-pointer"
                              onClick={() => {
                                updateRow(
                                  item,
                                  key,
                                  index,
                                  indexValue,
                                  editData
                                );
                                setEditingIndex(null);
                                setEditingdata(null);
                              }}
                            />
                            <XMarkIcon
                              className="h-6 w-6 text-red-400 hover:text-red-600 cursor-pointer"
                              onClick={() => {
                                setEditingIndex(null);
                                setEditingdata(null);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <PencilIcon
                              className="h-6 w-6 text-gray-400 hover:text-black cursor-pointer "
                              onClick={() => {
                                setEditingIndex({
                                  index: index,
                                  itemIndex: itemIndex,
                                  indexValue: indexValue,
                                });
                                setEditingdata(item);
                              }}
                            />
                            <TrashIcon
                              className="h-6 w-6 text-red-200 hover:text-red-600 cursor-pointer "
                              onClick={() => {
                                openModalDelete(key, index, indexValue);
                              }}
                            />
                          </>
                        )}
                      </td>
                    )}
                    {editingIndex?.indexValue === indexValue &&
                    editingIndex?.index === index &&
                    editingIndex?.itemIndex === itemIndex ? (
                      <>
                        <td className="border">
                          <input
                            className="text-center  w-full"
                            type="text"
                            value={editData.noArticle}
                            onChange={(e) => {
                              setEditingdata({
                                ...editData,
                                noArticle: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <td className="border">
                          <input
                            className="text-center w-full"
                            type="text"
                            value={editData.content}
                            onChange={(e) => {
                              setEditingdata({
                                ...editData,
                                content: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <td className="border">
                          <input
                            className="text-center w-full"
                            type="number"
                            value={editData.pts}
                            onChange={(e) => {
                              setEditingdata({
                                ...editData,
                                pts: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <td className="border">
                          <input
                            className="text-center w-full"
                            type="texte"
                            inputMode="numeric"
                            value={editData.quantite}
                            min="0"
                            placeholder="0"
                            onInput={(e) =>
                              handleInputValidation(
                                e,
                                item,
                                index,
                                tab.label,
                                true
                              )
                            }
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="border">
                          <div className=" text-center w-full">
                            {item.noArticle}
                          </div>
                        </td>
                        <td className="border ">
                          <div className=" text-center w-full">
                            {item.content}
                          </div>
                        </td>
                        <td className="border">
                          <div className=" text-center w-full">{item.pts}</div>
                        </td>
                        <td className="border p-0">
                          <input
                            type="text"
                            min="0"
                            placeholder="0"
                            inputMode="numeric"
                            onInput={(e) =>
                              handleInputValidation(e, item, index, tab.label)
                            }
                            className={`text-center w-full h-10 ${
                              item.quantite > 0 ? " font-bold text-lg" : ""
                            }`}
                            value={item.quantite}
                          />
                        </td>
                      </>
                    )}
                    {!editMode && (
                      <td className="border">
                        <div className="text-center w-full">
                          {Number(String(item.quantite).replace(",", ".")) *
                            Number(String(item.pts).replace(",", "."))}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
                {editMode && (
                  <tr>
                    <td colSpan="5" className="py-2">
                      <button onClick={() => addRow(key, index)}>
                        <PlusCircleIcon className="h-6 w-6 text-black-900 hover:text-red-600 cursor-pointer " />
                      </button>
                    </td>
                  </tr>
                )}
              </>
            ))
        ) : (
          <>
            {entries?.[0]?.[1]?.map((item, index) => (
              <tr key={index}>
                <td className="border">{item.noArticle}</td>
                <td className="border">{item.content}</td>
                <td className="border">{item.pts}</td>
                <td className="border p-0 h-full">
                  <input
                    type="text"
                    inputMode="numeric"
                    min="0"
                    placeholder="0"
                    className={`text-center w-full h-10 ${
                      item.quantite > 0 ? " font-bold text-lg" : ""
                    }`}
                    value={item.quantite}
                    onInput={(e) =>
                      handleInputValidation(e, item, index, tab.label)
                    }
                  />
                </td>
                <td className="border">{item.quantite * item.pts}</td>
              </tr>
            ))}
          </>
        );
      })}
      {isModalDeleteOpen && (
        <Transition appear show={isModalDeleteOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={closeModalDelete}
          >
            <div className="min-h-screen px-4 text-center flex items-center justify-center">
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

              <div className="inline-block align-middle bg-white rounded p-4 text-left overflow-hidden shadow-xl transform transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Confirmer la suppression
                </Dialog.Title>

                <Dialog.Description as="p">
                  Êtes-vous sûr de vouloir supprimer cet élément ?
                </Dialog.Description>

                <button
                  className="border bg-red-400 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4 mb-4"
                  onClick={confirmDelete}
                >
                  Supprimer
                </button>

                <button
                  className="border bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4 mb-4"
                  onClick={closeModalDelete}
                >
                  Annuler
                </button>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </tbody>
  );
};

const TableInformations = ({ informations, handleChange }) => {
  return (
    <table className="table-auto  w-full">
      <tbody>
        <tr className="bg-white">
          <th className="px-4 py-2 border w-1/4">Référence</th>
          <td className="px-4 py-2 border">
            <input
              className="w-full h-10 text-lg font-bold"
              type="text"
              inputMode="numeric"
              name="reference"
              value={informations.reference}
              onChange={handleChange}
            />
          </td>
        </tr>
        <tr className="bg-white ">
          <th className="px-4 py-2 border w-1/4">Nom</th>
          <td className="px-4 py-2 border">
            <input
              className="w-full h-10 text-lg font-bold"
              type="text"
              name="name"
              value={informations.name}
              onChange={handleChange}
            />
          </td>
        </tr>
        {/* <tr className="bg-white">
          <th className="px-4 py-2 border w-1/4">Adresse</th>
          <td className="px-4 py-2 border">
            <input
              className="w-full h-10 text-lg font-bold"
              type="text"
              name="address"
              value={informations.address}
              onChange={handleChange}
            />
          </td>
        </tr> */}
        <tr className="bg-white">
          <th className="px-4 py-2 border w-1/4">Commune</th>
          <td className="px-4 py-2 border">
            <input
              className="w-full h-10 text-lg font-bold"
              type="text"
              name="commune"
              value={informations.commune}
              onChange={handleChange}
            />
          </td>
        </tr>
        {/* <tr className="bg-white">
          <th className="px-4 py-2 border w-1/4">Téléphone</th>
          <td className="px-4 py-2 border">
            <input
              className="w-full h-10 text-lg font-bold"
              type="tel"
              name="phone"
              maxLength="10"
              pattern="[0-9]{10}"
              title="Le numéro de téléphone doit être composé de 10 chiffres."
              value={informations.phone}
              onChange={handleChange}
            />
          </td>
        </tr> */}
        <tr className="bg-white">
          <th className="px-4 py-2 border">Date et horaires du rendez-vous</th>
          <td className="px-4 py-2 border">
            <input
              className="w-full h-full"
              type="datetime-local"
              name="appointment"
              value={informations.appointment}
              onChange={handleChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const TableRecap = ({
  tabsData,
  HandleDownloadToPdf,
  handleSave,
  editMode,
  handleQuantityChange,
  handleComment,
  informationsCompleted,
}) => {
  const { userInfo } = useSelector((state) => state.auth);
  const recapTab = tabsData.find((tab) => tab.label === "Récap");
  let commentValue = "";
  if (recapTab && Object.keys(recapTab.value).length !== 0) {
    commentValue = recapTab.value;
  }

  const handleInputValidation = (evt, item, index, label) => {

    const value = evt.target.value;

    const itemQuantite = item.quantite;

    if (value === "" && itemQuantite?.toString().length < 2) {
      handleQuantityChange(item, index, label, "");
      return; // Sortir de la fonction tôt
    }

    // Remplacer les virgules par des points pour uniformiser le format décimal
    let normalizedValue = value.replace(",", ".");

    // Utiliser une expression régulière pour vérifier si la valeur est un nombre positif sans signes préfixes
    const isPositiveNumberWithoutSign =
      /^([0-9]+([.,][0-9]*)?|[.,][0-9]+)$/.test(normalizedValue);

    if (
      (itemQuantite === 0 || itemQuantite?.toString() === "0") &&
      value !== "0" + "."
    ) {
      normalizedValue = value.replace(/^0+/, "");
    }

    if (isPositiveNumberWithoutSign) {
      // Si c'est un nombre positif sans signe, procéder avec la valeur normalisée
      handleQuantityChange(item, index, label, normalizedValue);
    } else {
      // Sinon, réinitialiser à la quantité précédente ou à 0
      handleQuantityChange(item, index, label, itemQuantite || 0);
    }
  };

  const filteredTabsData = tabsData.filter(
    (tab) => tab.label !== "Informations" && tab.label !== "Récap"
  );

  // Vérifiez si toutes les quantités sont égales à 0
  const isTableEmpty = filteredTabsData.every((tab) =>
    Object.values(tab.value).every((array) =>
      array.every((item) => item.quantite == 0)
    )
  );
  const totalSum = filteredTabsData.reduce(
    (total, tab) =>
      total +
      Object.values(tab.value).reduce(
        (subTotal, array) =>
          subTotal +
          array.reduce(
            (itemTotal, item) => itemTotal + item.quantite * item.pts,
            0
          ),
        0
      ),
    0
  );

  return (
    <>
      <tbody>
        {filteredTabsData.map((tab, tabIndex) =>
          Object.entries(tab.value)
            .sort((a, b) => a[1][0]?.noArticle - b[1][0]?.noArticle)
            .map(([key, array], itemIndex) => (
              <>
                <tr>
                  <th
                    colSpan="5"
                    className="border bg-gray-200 font-bold text-center"
                  >
                    {key}
                  </th>
                </tr>
                {array.map((item, index) => (
                  <tr
                    key={index}
                    className={`${item.quantite > 0 ? "bg-green-100" : ""}`}
                  >
                    <td className="border">{item.noArticle}</td>
                    <td className="border">{item.content}</td>
                    <td className="border">{item.pts}</td>
                    <td className="border p-0">
                      {editMode ? (
                        <input
                          type="text"
                          className={`text-center w-full h-10 ${
                            item.quantite > 0
                              ? "bg-green-100 font-bold text-lg"
                              : ""
                          }`}
                          inputMode="numeric"
                          value={item.quantite}
                          min={0}
                          placeholder="0"
                          onInput={(e) => {
                            handleInputValidation(e, item, tabIndex, tab.label);
                          }}
                        />
                      ) : (
                        <p
                          className={`${
                            item.quantite > 0 ? "font-bold text-lg" : ""
                          }`}
                        >
                          {item.quantite}
                        </p>
                      )}
                    </td>

                    <td className="border">{item.quantite * item.pts}</td>
                  </tr>
                ))}
              </>
            ))
        )}
      </tbody>
      <tfoot>
        {!isTableEmpty && (
          <>
            <tr>
              <td colSpan="4" className="border py-4 bg-gray-300 font-bold">
                Total Pts
              </td>
              <td className="border bg-gray-300 font-bold ">{totalSum}</td>
            </tr>
            <tr>
              <td colSpan="5" className="w-96">
                <textarea
                  name="comments"
                  defaultValue={commentValue}
                  rows="5"
                  cols="45"
                  className="border-2 border-black resize"
                  placeholder="Un commentaire ?"
                  style={{ maxWidth: "100%" }}
                  onChange={handleComment}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="5" className="text-center w-96 ">
                <div>
                  {informationsCompleted ? (
                    <div className="flex items-center justify-center mt-5">
                      <div
                        className="flex mr-2 px-4 py-2  border rounded-[12px] hover:bg-green-200 "
                        onClick={() => HandleDownloadToPdf()}
                      >
                        <FaCloudDownloadAlt className="mr-2" size={25} />
                        <p>Exporter en PDF</p>
                      </div>

                      {userInfo && (
                        <div className="flex">
                          <div>
                            <button
                              className="px-4 py-2 border text-black rounded-[12px] hover:bg-green-200"
                              onClick={() =>
                                handleSave(
                                  editMode &&
                                    (userInfo.role === "admin" ||
                                      userInfo.role === "gestionnaire")
                                    ? "Pending"
                                    : "inProgress"
                                )
                              }
                            >
                              {editMode &&
                              (userInfo.role === "admin" ||
                                userInfo.role === "gestionnaire")
                                ? "Enregistrer"
                                : "Enregistrer en brouillon"}
                            </button>
                          </div>
                          <div>
                            <button
                              className="px-4 py-2 border text-black rounded-[12px] hover:bg-green-200"
                              onClick={() =>
                                handleSave(
                                  editMode &&
                                    (userInfo.role === "admin" ||
                                      userInfo.role === "gestionnaire")
                                    ? "Validate"
                                    : "Pending"
                                )
                              }
                            >
                              Enregistrer et valider
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p className="font-bold">
                      Veuillez completer les informations
                    </p>
                  )}
                </div>
              </td>
            </tr>
          </>
        )}
      </tfoot>
    </>
  );
};

export { TableInformations, Table, TableRecap };
