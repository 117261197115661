import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import Main from "./containers/maps";
import Header from "./Components/attachement/header";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { persistor, store } from "./store/store";
import LoginScreen from "./containers/login";
import { Helmet } from "react-helmet";
import Admin from "./containers/admin/dashboard";
import AdminNavigation from "./containers/admin/AdminNavigation";
import AdminRoute from "./middleware/AdminRoute";
import Cookies from "js-cookie";
import User from "./containers/admin/User";
import { makeRequest } from "./Utils";
import AttachementConfig from "./containers/admin/AttachementConfig";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Attachements from "./containers/Attachement";
import UserAttachements from "./containers/admin/UserAttachements";
import UpdatePasswordScreen from "./containers/UpdatePassword";
import NewAttachement from "./containers/NewAttachement";
import AttachementList from "./containers/AttachementList";
import UserPage from "./containers/User";
import ChooseNavigation from "./containers/ChooseNavigation";
import ProtectedRoute from "./middleware/ProtectedRoute";
import { PersistGate } from "redux-persist/integration/react";

function InnerApp() {
  return (
    <>
      <Helmet>
        <title>Maps</title>
      </Helmet>

      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route
          path="updatePassword/:token"
          element={<UpdatePasswordScreen />}
        />
        {/* RouteProteger */}
        <Route path="" element={<ProtectedRoute />}>
          <Route
            path="/attachement"
            element={
              <>
                <Header />
                <NewAttachement />
              </>
            }
          />
          <Route path="/" element={<ChooseNavigation />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/maps" element={<Main />} />
          <Route path="/qrcode" element={<Main qrocdeParam={true} />}></Route>

          <Route
            path="/attachements"
            element={
              <>
                <Header />
                <AttachementList />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <Header />
                <UserPage />
              </>
            }
          />
          <Route
            path="/attachement/:id"
            element={
              <>
                <Header />
                <Attachements />
              </>
            }
          />

          <Route path="/admin" element={<AdminRoute />}>
            <Route
              index
              element={
                <div className="flex">
                  <AdminNavigation />
                  <div className="overflow-auto w-full">
                    <Admin />
                  </div>
                </div>
              }
            />
            <Route
              exact
              path="/admin/users"
              element={
                <div className="flex">
                  <AdminNavigation />
                  <div className="overflow-auto w-full">
                    <User />
                  </div>
                </div>
              }
            />
            <Route
              exact
              path="/admin/attachment/config"
              element={
                <div className="flex">
                  <AdminNavigation />
                  <div className="overflow-auto w-full">
                    <AttachementConfig />
                  </div>
                </div>
              }
            />
            <Route
              exact
              path="/admin/userAttachements"
              element={
                <div className="flex">
                  <AdminNavigation />
                  <div className="overflow-auto w-full">
                    <UserAttachements />
                  </div>
                </div>
              }
            />
            <Route
              exact
              path="/admin/attachement/:id"
              element={
                <div className="flex">
                  <AdminNavigation />
                  <div className="overflow-auto w-full">
                    <Attachements />
                  </div>
                </div>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

function App() {
  useEffect(() => {
    if (!Cookies.get("visited")) {
      makeRequest("api/stats/newVisitor", "POST");

      Cookies.set("visited", "true", { expires: 365 });
    }
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ToastContainer />
          <InnerApp />
        </Router>
      </PersistGate>
    </Provider>
  );
}

const rootElement = document.getElementById("root");
createRoot(rootElement).render(
  //<React.StrictMode>
  <App />
  // </React.StrictMode>
);
