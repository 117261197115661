import React,{ useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {  useSelector } from "react-redux";
import { CircularProgress } from "../Components/CircularProgress";

export default function ProtectedRoute()  {
 const { loading, userInfo } = useSelector((state) => state.auth);
 const navigate = useNavigate();
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);

 const isQrcodeWithParams =
   location.pathname === "/qrcode" &&
   searchParams.has("latitude") &&
   searchParams.has("longitude");


  useEffect(() => {
    if (!userInfo && !loading && !isQrcodeWithParams) {
      navigate("/login", {
        state: { from: location.pathname + location.search },
      });
    }
  }, [
    userInfo,
    navigate,
    loading,
    navigate,
    location.pathname,
    isQrcodeWithParams,
  ]);

  if (loading  ) {
    return (
      <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
        <CircularProgress />
      </div>
    );
  }

  return <Outlet />;

}
