import React,{ useState } from "react";
import { TableInformations, Table, TableRecap } from "./table";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { makeRequest } from "../../Utils";
import { toast } from "react-toastify";
import { RotateSpinner } from "react-spinners-kit";


let toastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export default function Tabs({ tabsData, onQuantityChange, all }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [informations, setInformations] = useState({
    reference: "",
    name: "",
    commune: "",
    appointment: "",
  });
  
  const [comment, setComment] = useState("");
  const [attachementId, setAttachementId] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const exportPDF = (sendToMail = false) => {
    if (!sendToMail) setLoading(true);
    // Récupérez les informations et récap

    const filteredTabsData = tabsData.filter(
      (tab) => tab.label !== "Récap" && tab.label !== "Informations"
    );

    // Créez une nouvelle instance de jsPDF
    const doc = new jsPDF();

    // Ajoutez les informations au document sous forme de tableau
    if (informations) {
      const keyMap = {
        appointment: "Date et horaires du rendez-vous",
        name: "Nom",
      };

      const infoArray = Object.entries(informations).map(([key, value]) => [
        keyMap[key] || key, // Utilisez la correspondance de clés si elle existe, sinon utilisez la clé originale
        value,
      ]);

      doc.autoTable({
        startY: 10,
        body: infoArray,
      });
    }

    // Définissez les en-têtes de la table
    const headers = ["No Article", "Content", "Pts", "Quantite", "Total"];

    // Préparez les données pour la table
    const data = [];

    let totalPts = 0;
    // Parcourez chaque onglet de tabsData
    let lastKey = null;
    filteredTabsData.forEach((tab) => {
      Object.entries(tab.value).forEach(([key, array]) => {
        if (array.some((item) => item.quantite > 0)) {
          // Si la clé a changé, ajoutez une nouvelle ligne avec la clé en colspan 5
          if (key !== lastKey) {
            data.push([
              {
                content: key,
                colSpan: 5,
                styles: {
                  halign: "center",
                  fillColor: [169, 169, 169],
                  textColor: [0, 0, 0],
                },
              },
              {},
              {},
              {},
              {},
            ]);
            lastKey = key;
          }
          array.forEach((item) => {
            if (item.quantite > 0) {
              data.push([
                item.noArticle,
                item.content,
                item.pts,
                item.quantite,
                item.pts * item.quantite,
              ]);
              totalPts += item.pts * item.quantite;
            }
          });
        }
      });
    });

    // Ajoutez la table au document
    doc.autoTable({
      startY: 60, // ajustez cette valeur en fonction de la hauteur de votre tableau d'informations
      head: [headers],
      body: data,
      theme: "grid",
    });

    // Après avoir dessiné toutes les tables, ajoutez le total
    doc.autoTable({
      startY: doc.previousAutoTable.finalY,
      body: [
        [
          {
            content: "total",
            colSpan: 4,
            styles: { halign: "center" },
          },
          {
            content: totalPts,
            colSpan: 1,

            styles: { halign: "right" },
          },
        ],
      ],
      theme: "grid",
      styles: { textColor: 255, fillColor: [26, 188, 156] },
      columnStyles: {
        0: { cellWidth: "auto" }, // 'total' prendra toute la place restante
        1: { cellWidth: "wrap" }, // 'totalPts' prendra une taille maximale du texte
      },
    });

    // Ajoutez le récapitulatif à la fin du document
    if (comment) {
      const finalY = doc.previousAutoTable.finalY; // récupère la position y de la fin de la dernière table

      doc.setFont("helvetica", "bold");
      doc.text(`Commentaire: `, 10, finalY + 10); // ajustez le +10 en fonction de l'espace que vous voulez laisser après la table

      doc.setFont("helvetica"); // pour le texte normal, ne définissez pas le style de la police
      doc.setFontSize(10); // définir la taille de la police à 10

      const maxWidth = 150; // définir la largeur maximale du texte
      const textLines = doc.splitTextToSize(`${comment}`, maxWidth); // diviser le texte en plusieurs lignes

      doc.text(textLines, 50, finalY + 10); // ajustez le 60 pour aligner ce texte avec "Commentaire:"
    }
    let fileName = "attachement.pdf";

    if (informations && informations.reference) {
      fileName = "attachement_" + informations.reference + ".pdf";
    }
    // Enregistrez le document
    if (sendToMail === false) {
      doc.save(fileName);
      setLoading(false);
    } else {
      const blobPDF = doc.output("blob");
      return { blobPDF: blobPDF, fileName: fileName };
    }
  };

  const handleSave = async (status) => {
    setLoading(true);
    // Trouver l'onglet "Informations" et lui assigner les nouvelles valeurs
    const infoTab = tabsData.find((tab) => tab.label === "Informations");
    if (infoTab) {
      infoTab.value = informations; // ou toute autre valeur que vous souhaitez assigner
    }

    const filteredTabsData = tabsData.filter(
      (tab) => tab.label !== "Récap" //TODO: remove  tab.label !== "Informations" &&
    );

    if (comment && comment.trim() !== "") {
      filteredTabsData.push({ label: "Récap", value: comment });
    }

    try {
      const formData = new FormData();
      if (attachementId) {
        formData.append("attachement", JSON.stringify(filteredTabsData));
        formData.append("status", status);
        formData.append("id", attachementId);

        const data = await makeRequest(
          "api/attachement/update",
          "PUT",
          null,
          formData
        );

        if (data?.id) setAttachementId(data.id);

        // Envoyer un mail aux gestionnaire
        await sendMailAttachmentFile(
          attachementId,
          status,
          informations.reference
        );
      } else {
        // Ajoutez les autres données au FormData
        // Créez un objet FormData
        const formData = new FormData();

        formData.append("attachement", JSON.stringify(filteredTabsData));
        formData.append("status", status);

        const data = await makeRequest(
          "api/attachement/create",
          "POST",
          null,
          formData
        );
        if (data?.id) setAttachementId(data.id);

        await sendMailAttachmentFile(data.id, status, informations.reference);
      }

      if (status === "Pending") {
        window.location.reload();
      }
      toast.success(
        "Les données ont été enregistrées avec succès",
        toastOptions
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const sendMailAttachmentFile = async (attachementId, status, reference) => {
    let pdfFile = null;
    let pdfFileName = null;

    if (status === "Pending") {
      const { blobPDF, fileName } = exportPDF(true);
      pdfFile = blobPDF;
      pdfFileName = fileName;

      const formDataMail = new FormData();
      formDataMail.append("id", attachementId);
      formDataMail.append("reference", reference);

      if (pdfFile) {
        const file = new File([pdfFile], pdfFileName);
        formDataMail.append("fileToAttach", file);

        await makeRequest(
          "api/attachement/sendMail",
          "POST",
          null,
          formDataMail
        );
      }
    }
  };



  const handleChange = (e) => {
    setInformations({
      ...informations,
      [e.target.name]: e.target.value,
    });
  };

  const handleQuantityChange = (selectedTab, index, key, newQuantity) => {
    
    onQuantityChange(selectedTab, index, key, newQuantity);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  function areInformationsComplete(informations) {
    const requiredProperties = ["reference", "name", "commune"];

    for (let property of requiredProperties) {
      if (
        !Object.prototype.hasOwnProperty.call(informations, property) ||
        informations[property] === ""
      ) {
        return false;
      }
    }

    return true;
  }

  // Vérifiez si toutes les quantités sont égales à 0
  const isTableEmpty = true;

  let tabsHeaders = tabsData;

  if (all) {
    tabsHeaders = tabsData.filter(
      (tab) => tab.label === "Informations" || tab.label === "Récap"
    );

    if (!tabsHeaders.some((tab) => tab.label === "All")) {
      tabsHeaders.splice(tabsHeaders.length - 1, 0, {
        label: "All",
        value: {},
      });
    }
  }

  return (
    <div className="w-full mt-2 h-full rounded border  border-slate-400 ">
      <div className="flex divide-x divide-slate-700 overflow-x-auto whitespace-nowrap sticky top-0 z-10 bg-white">
        {tabsHeaders.map((tab, index) => (
          <button
            key={index}
            onClick={() => setSelectedTab(index)}
            className="w-full p-4 uppercase font-medium bg-slate-200 hover:bg-slate-300"
          >
            {tab?.label}
          </button>
        ))}
      </div>
      <div className="text-slate-700 px-2 pt-2 pb-2 h-full ">
        <div>
          {tabsHeaders[selectedTab]?.label === "Récap" && (
            <>
              {!isTableEmpty && (
                <button
                  className="px-4 py-2 mb-4 border text-black rounded-[12px] hover:bg-green-200"
                  onClick={() => exportPDF()}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaCloudDownloadAlt style={{ marginRight: "10px" }} />
                    <span>Exporter en PDF</span>
                  </div>
                </button>
              )}
            </>
          )}
          <table className="table-auto table w-full text-center border-collapse">
            <thead>
              {tabsHeaders[selectedTab]?.label !== "Informations" && (
                <tr>
                  <th className="border w-1/6">No Article</th>
                  <th className="border w-2/6">Désignation</th>
                  <th className="border w-1/6">Pts</th>
                  <th className="border w-1/6">Quantité</th>
                  <th className="border w-1/6">Total</th>
                </tr>
              )}
            </thead>
            {tabsHeaders[selectedTab]?.label === "Informations" ? (
              <TableInformations
                informations={informations}
                handleChange={handleChange}
              />
            ) : tabsHeaders[selectedTab]?.label === "Récap" ? (
              <TableRecap
                informationsCompleted={areInformationsComplete(informations)}
                tabsData={tabsData}
                HandleDownloadToPdf={exportPDF}
                handleSave={handleSave}
                handleComment={handleCommentChange}
              />
            ) : (
              <Table
                all={all}
                selectedTab={selectedTab}
                handleQuantityChange={handleQuantityChange}
                tabsData={tabsData}
              />
            )}
          </table>
        </div>
      </div>
      {isLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-white bg-opacity-80 flex justify-center items-center z-50">
          <RotateSpinner size={45} color="#318ce7" loading={isLoading} />
        </div>
      )}
    </div>
  );
}
