import { useState } from "react";
import DragToReorderList from "../../Components/DragList/DragList";

const AttachmentOrderModal = ({
  orderList,
  handleOrderSave,
  handleModalOpen,
}) => {
  const [items, setItems] = useState(orderList);

  const handleChange = (items) => {
    setItems(items);
  };
  return (
    items && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-8 max-w-md mx-auto">
          <h2 className="text-2xl font-bold mb-6 text-center">
            Modifier l&apos;ordre des sections
          </h2>
          <div className="flex  justify-center">
            <DragToReorderList
              orderList={items}
              handleOrderChange={handleChange}
            />
          </div>
          <div className="flex justify-end space-x-4 mt-6">
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                handleOrderSave(items);
                handleModalOpen(false);
              }}
            >
              Sauvegarder
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleModalOpen(false)}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default AttachmentOrderModal;
